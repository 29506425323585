.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.scrollbar-width-thin {
  scrollbar-width: thin;
}
.navbar-vertical{
  background: #d3d3d3 !important;
}
.navbar-classic{
  background: #042940 !important;
}
.navbar-heading{
  color: #f54b00 !important;
}
.home-title{
  color: #042940 !important;
}
.ravianna
{
  width:50%;
  margin: 0 auto;
}
.home img 
{
  width: 65%;
  height: 400px;
}
.navbar-vertical .navbar-nav .nav-item .nav-link.active {
  color: #042940 !important;
}
.navbar-vertical .navbar-brand img {
  height: 2.875rem !important;
}
.btn-check:focus + .btn-primary, .btn-primary:focus {

  box-shadow: 0 0 0 0.25rem rgb(132 131 139 / 50%) !important;
}